<template>
  <div class="cameraPlayer">
    <div id="cameraPlayer" :class="{r0: isFull}">
      <img @click="$emit('update:isOpen', false)" class="close" :src="close" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isFull: Boolean,
    isOpen: Boolean,
  },
  data() {
    return {
      close: require('@/assets/img/camera_close@2x.png'),
    };
  },
};
</script>

<style lang="scss" scoped>
.cameraPlayer {
  height: 210px;
  width: 320px;
  background-color: #d8d8d8;
  position: absolute;
  top: 0;
  right: -348px;
  z-index: 3;
  &.r0{
    right: 0;
  }
  img {
    position: absolute;
    cursor: pointer;
    z-index: 6;
    &.close {
      top: 0;
      right: 0;
      width: 38px;
      height: 38px;
    }
  }
}
</style>
<style lang="scss">
.cameraPlayer #cameraPlayer > div > div{
  opacity: 0 !important;
}
</style>
