<template>
  <div class="replayLive">
    <authentication>
      <live-page-nav
        play-type="replay"
        :course-name="$route.query.sectionName"
      ></live-page-nav>
    </authentication>
    <div class="playerBox" v-if="showHT">
      <div class="playerBox-left">
        <main-player @contextmenu.prevent.stop/>
        <camera-player
          v-show="isOpen"
          v-model:is-open="isOpen"
          v-model:is-toggle="isToggle"
          :is-full="isFull"
        />
        <img v-if="isOpen" @click="toggleCamera" class="toggleCamera" :src="toggleImg" alt="" />
        <!-- <img v-if="!isPlay" class="playBtn" @click="play" :src="playBtn" alt=""> -->
        <img v-show="!isPlay && isOpen" class="miniPlay" @click="play" :src="playBtn" alt="">
        <text-go>{{userInfo.userName}}_{{userInfo.userId}}</text-go>
        <player-control
          v-model:is-expend="isExpend"
          v-model:is-full="isFull"
          v-model:is-play="isPlay"
          v-model:is-open="isOpen"
          v-model:is-toggle="isToggle"
          :cur-time="curTime"
          :total-time="totalTime"
          @onFull="onFull"
          :total="total"
          :progress="progress"
        />
      </div>
      <div class="playerBox-right" :class="{m0: !isOpen}" v-if="!isExpend">
        <live-tab-bar :tabs="tabs" v-model:active-tab="activeTab"/>
        <chat-page :cur-time="currentTime" :chat-list="chatList" v-if="activeTab === 0" />
        <ppt-page :cur-time="curTime" :chpter-list="chpterList" v-else />
      </div>
    </div>
  </div>
</template>
<script>
import { onUnmounted } from 'vue';
import {
  getHT,
  formatSecond,
  resetNodeStyle,
  launchFullScreen,
  exitFullScreen,
  fullScreenListener,
} from '@/utils/common';
import Authentication from '@/components/Authentication/index.vue';
import LiveTabBar from '@/components/LiveTabBar.vue';
import { mapGetters } from 'vuex';
import LivePageNav from '@/components/LivePageNav.vue';
import MainPlayer from './components/MainPlayer.vue';
import CameraPlayer from './components/CameraPlayer.vue';
import PptPage from './components/PptPage.vue';
import PlayerControl from './components/PlayerControl.vue';
import ChatPage from './components/ChatPage.vue';
import TextGo from '../../components/TextGo.vue';

export default {
  setup() {
    let isHT = true;
    onUnmounted(() => {
      console.log('卸载');
      isHT = false;
    });
    return {
      isHT,
    };
  },
  components: {
    LivePageNav,
    Authentication,
    LiveTabBar,
    CameraPlayer,
    MainPlayer,
    PptPage,
    PlayerControl,
    ChatPage,
    TextGo,
  },
  data() {
    return {
      tabs: [
        {
          label: '聊天',
        },
        {
          label: '章节',
        },
      ],
      activeTab: 0,
      curTime: '',
      totalTime: '',
      chpterList: [],
      chatList: [],
      total: null,
      currentTime: null,
      progress: null,
      isExpend: false,
      isFull: false,
      isOpen: true,
      isPlay: true,
      isToggle: false,
      leftWidth: window.innerWidth - 348,
      leftHeight: window.innerHeight - 112,
      playBtn: require('@/assets/img/play_video@2x.png'),
      toggleImg: require('@/assets/img/qiehuan@2x.png'),
    };
  },
  computed: {
    ...mapGetters(['userInfo']),
    showHT() {
      return this.isHT;
    },
  },
  mounted() {
    getHT({
      courseId: this.$route.query.courseId,
      courseDetailId: this.$route.query.courseDetailId,
    }, this.setHT);
    setTimeout(() => {
      // 全屏侦听器
      const e = document.querySelector('.playerBox-left');
      const cb = (isFull) => {
        if (!isFull) {
          this.isFull = isFull;
          const video = document.querySelector('.player');
          const camera = document.querySelector('.cameraPlayer');
          const size = { w: this.leftWidth, h: this.leftHeight };
          this.exitFull(camera, video, size);
          this.toggleFullBtn(isFull);
        }
      };
      fullScreenListener(cb, e);
    }, 1000);
  },
  methods: {
    setHT(HT) {
      // this.store.getState().WHITEBOARD[t.pageIndex].backgroundColor
      // 初始化播放器
      HT.mainPlayer('mainPlayer', 'videoId', (player) => {
        console.log('创建视频播放器成功', player);
        player.setAttribute('autoplay', false);
      });
      HT.whiteboardPlayer('pptPlayer', 'modPPTPlayer', (player) => {
        console.log('课件播放器 => 初始化成功', player);
        console.log('---', player.Page.store.getState());
      });
      // 初始化摄像头
      HT.camera('cameraPlayer', 'cameraPlayer2', () => {
        console.log('摄像头初始化成功');
      });
      // 获取播放时间
      HT.on('live:duration', (currentTime, duration, currentPercent) => {
        // console.log(currentTime, duration, currentPercent);
        this.total = Math.floor(duration);
        this.currentTime = Math.floor(currentTime);
        this.progress = (currentPercent * 100).toFixed(2);
        this.curTime = formatSecond(currentTime);
        this.totalTime = formatSecond(duration);
      });
      // 获取章节
      HT.on('live:chapter:list', (chpterList) => {
        this.chpterList = chpterList;
      });
      // 获取聊天
      HT.on('live:message:append', (chatList) => {
        console.log(chatList);
        this.chatList = chatList;
      });
      // 设置声音
      let volume = sessionStorage.getItem('volume') || 100;
      volume = typeof volume === 'string' ? Number(volume) : volume;
      HT.volume(volume / 100);
    },
    play() {
      this.$HT.play();
      this.isPlay = !this.isPlay;
    },
    toggleCamera() {
      const video = document.querySelector('.player');
      const camera = document.querySelector('.cameraPlayer');
      const fullSize = { w: window.innerWidth, h: window.innerHeight - 52 };
      const leftSize = { w: this.leftWidth, h: this.leftHeight };
      const rightSize = { w: 320, h: 210 };
      const leftPosition = { left: '0px' };
      const rightPosition = { left: 'auto', right: '-348px' };
      this.toggleFullBtn(this.isFull);
      if (this.isFull) {
        if (this.isToggle) {
          resetNodeStyle(video, fullSize, leftPosition, 3);
          resetNodeStyle(camera, rightSize, { left: 'auto', right: '0' }, 4);
        } else {
          resetNodeStyle(video, rightSize, { left: 'auto', right: '0' }, 4);
          resetNodeStyle(camera, fullSize, leftPosition, 3);
        }
      } else if (!this.isFull) {
        if (this.isToggle) {
          resetNodeStyle(video, leftSize, leftPosition);
          resetNodeStyle(camera, rightSize, rightPosition);
        } else {
          resetNodeStyle(video, rightSize, rightPosition);
          resetNodeStyle(camera, leftSize, leftPosition);
        }
      }
      this.isToggle = !this.isToggle;
    },
    onFull(val) {
      const e = document.querySelector('.playerBox-left');
      const video = document.querySelector('.player');
      const camera = document.querySelector('.cameraPlayer');
      const size = { w: this.leftWidth, h: this.leftHeight };
      const fullSize = { w: window.innerWidth, h: window.innerHeight + 60 };
      this.toggleFullBtn(!val);
      if (val) {
        exitFullScreen();
        this.exitFull(camera, video, size);
      } else {
        if (this.isToggle) {
          resetNodeStyle(camera, fullSize, {}, 3);
          resetNodeStyle(video, {}, { right: '0px' }, 4);
        } else {
          resetNodeStyle(video, fullSize, {}, 3);
          resetNodeStyle(camera, {}, { right: '0px' }, 4);
        }
        launchFullScreen(e);
      }
      this.isFull = !val;
    },
    exitFull(camera, video, size) {
      if (this.isToggle) {
        resetNodeStyle(camera, size, {});
        resetNodeStyle(video, {}, { right: '-348px' });
      } else {
        resetNodeStyle(video, size, {});
        resetNodeStyle(camera, {}, { right: '-348px' });
      }
    },
    toggleFullBtn(isFull) {
      const toggle = document.querySelector('.toggleCamera');
      const miniPlay = document.querySelector('.miniPlay');
      if (isFull) {
        resetNodeStyle(toggle, {}, { right: '286px' }, 5);
        resetNodeStyle(miniPlay, {}, { right: '138px' }, 5);
      } else {
        resetNodeStyle(toggle, {}, { right: '-62px' }, 5);
        resetNodeStyle(miniPlay, {}, { right: '-210px' }, 5);
      }
    },
  },
};

</script>
<style lang="scss" scoped>
.replayLive{
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  .playerBox{
    height: calc(100% - 60px);
    width: 100%;
    display: flex;
    &-left{
      background-color: #000;
      flex: 1;
      position: relative;
      .playBtn, .miniPlay, .toggleCamera{
        position: absolute;
        z-index: 5;
        cursor: pointer;
      }
      .playBtn{
        width: 112px;
        height: 112px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -108px);
      }
      .miniPlay{
        width: 44px;
        height: 44px;
        right: -210px;
        top: 83px;
      }
      .toggleCamera{
        width: 24px;
        height: 24px;
        right: -62px;
        top: 176px;
      }
    }
    &-right{
      margin-left: 28px;
      margin-top: 210px;
      height: calc(100% - 210px);
      width: 320px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      &.m0{
        margin-top: 0;
        height: 100%;
      }
    }
  }
}
</style>
