<template>
  <div class="playerControlBox">
    <el-slider
      style="width: 100%"
      v-model="playProgress"
      :show-tooltip="false"
      :step="0.01"
      @change="changeProgress"
    ></el-slider>
    <div class="playerControl">
      <div class="playerControl-left">
        <img @click="onPlay" class="play" :src="!isPlay ? play : pause" alt="">
        <img @click="onStop" class="play" :src="stop" alt="">
        <div class="time">{{curTime}}</div>
        <div class="gang">/</div>
        <div class="time">{{totalTime}}</div>
      </div>
      <div class="playerControl-right">
        <img @click="toggleCamera" :src="isOpen ? camera_open : camera_close" alt="">
        <img class="loudspeaker" @click="closeVolume" :src="volume === 0 ? silence : loudspeaker">
        <el-slider
          style="width:120px;"
          v-model="volume"
          :show-tooltip="false"
          @change="changeVolume"
        ></el-slider>
        <span class="volume">{{volume}}%</span>
        <div class="speed">
          <span @click="isSpeed = !isSpeed">{{speedText}}</span>
          <hcp-popover @change="changeSpeed" :list="speedList" v-model:visible="isSpeed" />
        </div>
        <div class="line">
          <img v-if="!showLineText" @click="showLine" :src="isLine ? line_active : line" alt="">
          <span v-else @click="showLine">{{lineText}}</span>
          <hcp-popover @change="changeLine" top="-10px" :list="lineList" v-model:visible="isLine" />
        </div>
        <img @click="expend" class="expend_r" :src="isExpend ? expend_l : expend_r" alt="">
        <img @click="onFull" :src="!isFull ? fullScreen : noFullscreen" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import HcpPopover from './HcpPopover.vue';

export default {
  components: { HcpPopover },
  props: {
    curTime: String,
    totalTime: String,
    isOpen: Boolean,
    isPlay: Boolean,
    isFull: Boolean,
    isExpend: Boolean,
    total: Number,
    progress: String,
  },
  data() {
    return {
      loudspeaker: require('@/assets/img/loudspeaker.png'),
      silence: require('@/assets/img/silence.png'),
      fullScreen: require('@/assets/img/fullScreen.png'),
      noFullscreen: require('@/assets/img/noFullscreen.png'),
      expend_r: require('@/assets/img/expend_r.png'),
      expend_l: require('@/assets/img/expend_l.png'),
      camera_open: require('@/assets/img/camera_open.png'),
      camera_close: require('@/assets/img/camera_gray@2x.png'),
      play: require('@/assets/img/play.png'),
      pause: require('@/assets/img/pause.png'),
      stop: require('@/assets/img/stop.png'),
      line: require('@/assets/img/lines@2x.png'),
      line_active: require('@/assets/img/line_active@2x.png'),
      isSpeed: false,
      isLine: false,
      speedText: '倍速',
      speedList: [
        {
          label: '正常播放',
          value: 1,
        },
        {
          label: '0.75x',
          value: 0.75,
        },
        {
          label: '1.2x',
          value: 1.2,
        },
        {
          label: '1.5x',
          value: 1.5,
        },
        {
          label: '1.8x',
          value: 1.8,
        },
        {
          label: '2x',
          value: 2,
        },
      ],
      lineList: [],
      volume: (sessionStorage.getItem('volume') && Number(sessionStorage.getItem('volume'))) || 100,
      playProgress: null,
      lineText: '',
      showLineText: false,
    };
  },
  watch: {
    curTime(val) {
      if (val === this.totalTime || val === '00:00:00') {
        this.$emit('update:isPlay', false);
      } else {
        this.$emit('update:isPlay', true);
      }
    },
    progress(val) {
      this.playProgress = parseFloat(val);
    },
  },
  methods: {
    // 暂停播放
    onPlay() {
      if (this.isPlay) {
        this.$HT.pause();
      } else {
        this.$HT.play();
      }
      this.$emit('update:isPlay', !this.isPlay);
    },
    // 停止重置
    onStop() {
      if (this.isPlay) {
        this.$HT.stop();
      } else {
        this.$HT.play();
      }
      this.$emit('update:isPlay', !this.isPlay);
    },
    // 切换摄像头
    toggleCamera() {
      this.$emit('update:isOpen', !this.isOpen);
    },
    // 播放速度
    changeSpeed(item) {
      this.isSpeed = false;
      if (item.value === 1) {
        this.speedText = '倍速';
      } else {
        this.speedText = item.label;
      }
      this.$HT.playRate(item.value);
    },
    // 显示线路
    showLine() {
      this.isLine = !this.isLine;
      this.lineList = [];
      this.$HT.getSource((num) => {
        for (let i = 0; i < num; i++) {
          this.lineList.push({
            key: i,
            label: `线路${i + 1}`,
          });
        }
      });
    },
    // 切换线路
    changeLine(item) {
      console.log(item);
      this.isLine = false;
      this.lineText = item.label;
      this.showLineText = true;
      this.$HT.changeSource(item.key, () => {
        console.log('设置成功');
      });
    },
    // 声音升降
    changeVolume(val) {
      this.$HT.volume(val / 100);
      sessionStorage.setItem('volume', val);
    },
    // 静音
    closeVolume() {
      const volume = sessionStorage.getItem('volume') || 100;
      if (this.volume > 0) {
        this.volume = 0;
        this.$HT.volume(0);
      } else {
        this.volume = typeof volume === 'string' ? Number(volume) : volume;
        this.$HT.volume(volume);
      }
    },
    // 全屏
    onFull() {
      this.$emit('onFull', this.isFull);
    },
    // 展开、缩进
    expend() {
      this.$emit('update:isExpend', !this.isExpend);
    },
    changeProgress(val) {
      const time = Math.floor((val / 100) * this.total);
      this.$HT.seek(time);
    },
  },
};
</script>

<style lang="scss">
.playerControlBox > .el-slider .el-slider__runway{
    margin: -2px 0 0;
    height: 2px;
    .el-slider__bar{
      height: 2px;
    }
    .el-slider__button-wrapper{
      top: -16px;
    }
    .el-slider__button{
      background-color: #37f;
      width: 8px;
      height: 8px;
      border: 0;
    }
  }
</style>
<style lang="scss" scoped>
.playerControlBox{
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  height: 52px;
  z-index: 5;
}

.playerControl{
  padding: 0 15px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &-left{
    display: flex;
    align-items: center;
    color: #909090;
    font-size: 14px;
    .play{
      margin-right: 26px;
      cursor: pointer;
    }
  }
  &-right{
    display: flex;
    align-items: center;
    .loudspeaker{
      margin: 0 15px;
    }
    .volume{
      color: #909090;
      margin-left: 15px;
    }
    .speed{
      height: 20px;
      line-height: 20px;
      color: #fff;
      font-size: 14px;
      padding: 0 6px;
      cursor: pointer;
      background-color: #37f;
      border-radius: 6px;
      margin: 0 30px 0 20px;
      position: relative;
    }
    .line{
      position: relative;
      margin-right: 20px;
      span{
        height: 20px;
        line-height: 20px;
        color: #fff;
        font-size: 14px;
        padding: 0 6px;
        cursor: pointer;
        background-color: #37f;
        border-radius: 6px;
      }
    }
    img{
      width: 34px;
      height: 34px;
      cursor: pointer;
    }
    .expend_r{
      margin: 0 20px 0 0;
    }
  }
}
</style>
