/* eslint-disable */
import app from '../main';
import {
  userJoinLiveCourse
} from '@/services/course';
import {
  ElMessage
} from 'element-plus'

export function getNumber(value) {
  if (!value) { return 0 }
  let num = 0;
  if (!value.trim()) return num
  if (value) {
     // 中文上标 ’认为是字符
    //匹配这些中文标点符号 。 ？ ！ ， 、 ； ： “ ” ‘ ' （ ） 《 》 〈 〉 【 】 『 』 「 」 ﹃ ﹄ 〔 〕 … — ～ ﹏ 
    const reg = /[\u3002|\uff1f|\uff01|\uff0c|\u3001|\uff1b|\uff1a|\u201c|\u201d|\u2018|\u2019|\uff08|\uff09|\u300a|\u300b|\u3008|\u3009|\u3010|\u3011|\u300e|\u300f|\u300c|\u300d|\ufe43|\ufe44|\u3014|\u3015|\u2026|\u2014|\uff5e|\ufe4f｜+｜-｜×｜÷]/gi;
    let str = typeof value === "string" ? value.replace(reg, ($0) => {
      console.log("---str:", $0)

      return " " + $0 + " ";
    }) : value;
    console.log("---str:", str)
    let newStr =
      str.replace(/\s+/gi, " ") + " ";
      if (newStr[0].includes(" ") || newStr[0].includes("s")) {
        newStr = newStr.substr(1, newStr.length - 1);
      }
    num = (newStr.match(/\s+/g) || []).length
  }
  return num;
}

/**
 * 初始化配置
 * @type {{video: {controls: boolean, preload: string, poster: boolean, autoplay: boolean}, config: {techOrder: string, controls: boolean}}}
 */
const extConf = {
  config: {
    techOrder: 'FLV', // 优选(FLV, FLASH, HLS)播放
    controls: false, // 视频播放器导航（全屏，刷新）
  },
  // 原生视频配置属性
  video: {
    autoplay: false, // video.autoplay
    controls: false, // video.controls
    preload: 'auto', // video.preload
    poster: true, // 视频海报图
    disablepictureinpicture: true
  },
};

/**
 * 欢拓实例初始化
 * @param accessToken
 * @returns {boolean|*}
 */
export const talkFunInit = (accessToken, callback) => {
  window.rpAccessToken = accessToken;
  const MT = window.MT;
  if (typeof MT === 'undefined') {
    return false;
  } else {
    if (app.config.globalProperties.HT == null) {
      /** 单例模式判断是否已经实例化了该对象 */
      const HT = new MT.SDK.main(accessToken, extConf, data => {
        console.log('init success:', data);
        app.config.globalProperties.$HT = HT;
        callback(HT)
      });
    }
  }
  return app.config.globalProperties.$HT
};

export const getHT = (params, callback) => {
  userJoinLiveCourse({
    ...params
  }).then(res => {
    talkFunInit(res, callback)
  })
}

/**
 * 秒换作时分秒
 */
export const formatSecond = (time) => {
  const Time = Math.floor(time);
  const curH = formatTime(Math.floor(Time / 60 / 60));
  const curM = formatTime(Math.floor((Time - curH * 60 * 60) / 60));
  const curS = formatTime(Time % 60);
  return `${curH}:${curM}:${curS}`;
}

function formatTime(val) {
  return val < 10 ? `0${val}` : val;
}

/**
 * 全屏
 */
export const launchFullScreen = (element) => {
  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen(); //火狐浏览器的请求全屏
  } else if (element.webkitRequestFullScreen) {
    element.webkitRequestFullScreen(); //谷歌、Safari浏览器的请求全屏
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen(); //IE浏览器的请求全屏
  } else {
    ElMessage.error('您的浏览器不兼容全屏模式，推荐您使用360，Chrome，safari，Firefox，QQ浏览器');
  }
}

/**
 * 退出全屏
 */
export const exitFullScreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitCancelFullScreen) {
    document.webkitCancelFullScreen();
  } else {
    ElMessage.error('您的浏览器不兼容全屏模式，推荐您使用360，Chrome，safari，Firefox，QQ浏览器');
  }
}

/**
 * 浏览器全屏事件--兼容
 * @param element
 * @returns {string}
 */
export function fullscreenEvent(element) {
  if (element.requestFullscreen) {
    return 'fullscreenchange';
  } else if (element.msRequestFullscreen) { //'IE'
    return 'MSFullscreenChange'
  } else if (element.mozRequestFullScreen) { //'火狐'
    return 'mozfullscreenchange'
  } else if (element.webkitRequestFullScreen) { //'谷歌、Safari'
    return 'webkitfullscreenchange'
  } else {
    console.error('您的浏览器不兼容全屏模式，推荐您使用360，Chrome，safari，Firefox，QQ浏览器');
  }
}

/**
 * 当前处于全屏的元素
 * @returns {*}
 */
export function currentFullscreenElement() {
  return document.fullscreenElement || document.mozFullScreenElement || document
    .webkitFullscreenElement;
}

/**
 * 全屏事件监听器
 */
export const fullScreenListener = (cb, element) => {
  let ele = element || document;
  let event = fullscreenEvent(ele);
  let isFull = false;
  document.addEventListener(event, function (e) {
    let activeElement = currentFullscreenElement();
    if (activeElement) {
      console.log('进入全屏');
      cb && cb(true);
    } else {
      console.log('退出全屏');
      cb && cb(false);
    }
  });
  ele.onfullscreenerror = function (event) {
    console.error("error :Unable to switch into full-screen mode. ", event)
  }
  return isFull;
}

/***
 * 将目标元素滚动到可视区域
 * @param scrollEl
 * @param targetEl
 */
export function scrollToTarget(scrollEl, targetEl) {
  if (!scrollEl || !targetEl) {
    return false;
  }
  const sObj = scrollEl.getBoundingClientRect();
  const tObj = targetEl.getBoundingClientRect();
  const sTop = sObj.top;
  const sBottom = sObj.bottom;
  const tTop = tObj.top;
  const tBottom = tObj.bottom;
  console.log(sTop, sBottom, tTop, tBottom);
  if (tTop < sTop || tBottom > sBottom) {
    /** 不在可视区域 */
    let top = scrollEl.scrollTop + tTop - sTop;
    scrollEl.scrollTo({
      behavior: 'smooth',
      left: 0,
      top: top
    });
  }
}

/**
 * 重置元素样式
 * @param {*} el
 * @param {*} size
 * @param {*} positions
 */
export const resetNodeStyle = (el, size, positions, zIndex) => {
  if (!el) return false;
  const {
    w,
    h
  } = size;
  const {
    left,
    right,
    bottom,
    margin
  } = positions;
  el.style.left = left;
  el.style.right = right;
  bottom ? el.style.bottom = bottom : null;
  margin ? el.style.margin = margin : null;
  el.style.width = w + "px";
  el.style.height = h + "px";
  el.style.zIndex = zIndex || 3;
}
