<template>
  <div class="player">
    <div id="mainPlayer" class="mainPlayer"></div>
    <div id="pptPlayer" class="pptPlayer"></div>
  </div>
</template>

<style lang="scss" scoped>
.player{
  height: calc(100% - 52px);
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 3;
  .mainPlayer, .pptPlayer{
    height: 100%;
    width: 100%;
  }
}
</style>
