<template>
  <div class="pptPage" v-if="chpterList.length">
    <ul>
      <li
        v-for="(item, i) in chpterList"
        :key="i"
        @click="onClick(i, item)"
        :class="{ active: activeIndex === i }"
      >
        <span class="title">{{i + 1}}、{{item.course}}</span>
        <span class="time">{{format(item.starttime)}}</span>
      </li>
    </ul>
  </div>
  <div class="noChat" v-else>暂无章节</div>
</template>

<script>
import { formatSecond } from '@/utils/common';

export default {
  props: {
    chpterList: Array,
    curTime: String,
  },
  data() {
    return {
      activeIndex: null,
    };
  },
  watch: {
    curTime(val) {
      this.chpterList.forEach((v, i) => {
        if (val === formatSecond(v.starttime)) this.activeIndex = i;
      });
    },
  },
  methods: {
    onClick(i, item) {
      this.activeIndex = i;
      this.$HT.seek(Math.floor(item.starttime));
    },
    format(val) {
      return formatSecond(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.noChat {
  margin-top: 15px;
  color: #909090;
  text-align: center;
}
.pptPage {
  margin: 20px 15px 0;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  ul {
    li {
      width: 100%;
      height: 34px;
      line-height: 34px;
      background-color: #f3f3f3;
      color: #909090;
      font-size: 14px;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      padding: 0 8px;
      cursor: pointer;
      .title{
        flex: 1;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
      }
      .time{
        width: 60px;
      }
      &.active {
        color: #3377ff;
        background-color: rgba(51, 119, 255, 0.3);
      }
    }
  }
}
</style>
