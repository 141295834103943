<template>
  <div class="hcpPopover" :style="{top:top}">
    <div class="hcpPopover-list" v-if="visible">
      <span @click="change(item)" v-for="(item, i) in list" :key="i">{{
        item.label
      }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: Boolean,
    list: Array,
    top: {
      type: String,
      default: '-17px',
    },
  },
  methods: {
    change(item) {
      this.$emit('change', item);
    },
  },
};
</script>

<style lang="scss" scoped>
.hcpPopover {
  z-index: 10;
  position: absolute;
  transform: translate(-50%, -100%);
  left: 50%;
  width: 64px;
  background-color: rgba(51, 59, 80, 0.8);
  font-size: 12px;
  color: #fff;
  &-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      margin-bottom: 8px;
      cursor: pointer;
    }
  }
}
</style>
