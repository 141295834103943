<template>
  <div ref="chatPage" class="chatPage" v-if="chatList.length">
    <ul>
      <li v-for="(item, i) in chatList" :key="i">
        <div class="left">
          <div>{{ item.nickname }}</div>
          <div class="chatText" v-html="item.message"></div>
        </div>
        <div class="right">{{ format(item.starttime) }}</div>
      </li>
    </ul>
  </div>
  <div class="noChat" v-else>暂无聊天</div>
</template>

<script>
import { formatSecond } from '@/utils/common';

export default {
  props: {
    chatList: Array,
    curTime: Number,
  },
  data() {
    return {
      cur: null,
    };
  },
  methods: {
    format(val) {
      return formatSecond(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.noChat {
  margin-top: 15px;
  color: #909090;
  text-align: center;
}
.chatPage {
  margin: 15px 10px 0;
  flex: 1;
  overflow-y: auto;
  ul {
    color: #909090;
    li {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .left {
        width: 256px;
        .chatText {
          color: #313131;
          padding: 6px 10px;
          font-size: 14px;
          margin: 10px 0;
          background-color: #fafafa;
          border-radius: 5px;
        }
      }
      .right {
        flex: 1;
        font-size: 12px;
      }
    }
  }
}
</style>
